import videoRollover from './video_rollovers.js';
import { googlyEyes } from './googlyEyes.js';
import wave from './wave.js';
import initializeTypingAnimation from './typewriter.js';

const funStuff = () => {

    wave(".wave");

    // if device can hover (not touch screen)
    if (window.matchMedia("(hover: hover)").matches) {
        videoRollover("#contact_btn", "#contact__video");
        videoRollover("#about_btn", "#about__video"); 
        googlyEyes("contact");
    }

    initializeTypingAnimation();
    
}

export { funStuff as default };
