// import Typewriter from 'typewriter-effect/dist/core';

// export default function typeTester() {
//   return {
//     size: 0,
//     breakpoints: [
//       { min: 200, max: 1000, columns: 1 },
//       { min: 51, max: 199, columns: 2 },
//       { min: 25, max: 50, columns: 3 },
//       { min: 16, max: 24, columns: 4 },
//     ],
//     leading: 1,
//     ot: [],
//     caseText: 'Aa',
//     caseClass: '',
//     align: 'center',
//     features: false,
//     figs: false,
//     slide: false,
//     typewriter: null,
//     interactingWithControls: false,
//     isTyping: false,
//     text: '',
//     get fontFeatures() {
//       return this.ot.length > 0 ? this.ot.map(f => `"${f}"`).join(', ') : 'normal';
//     },
//     get editableStyle() {
//       return {
//         'font-family': this.fontName ? `'${this.fontName}'` : 'sans-serif',
//         'font-size': `${this.size}px`,
//         'text-align': this.align || 'center',
//         'font-feature-settings': this.fontFeatures,
//         'line-height': this.leading,
//         'columns': this.breakpoints.find(b => this.size >= b.min && this.size <= b.max).columns,
//       };
//     },
//     initAlpine() {
//       // console.log('Data attributes:', this.$el.dataset);
//       this.size = parseInt(this.$el.dataset.size) || 60;
//       this.ot = this.$el.dataset.ot ? this.$el.dataset.ot.split(",") : [];
//       this.fontName = this.$el.dataset.fontName || 'sans-serif';
//       this.leading = parseFloat(this.$el.dataset.leading) || 1.5; // Read line-height
//       this.align = this.$el.dataset.alignment || 'center'; // Read alignment

//       const font = new FontFace(this.$el.dataset.fontName, 'url(' + this.$el.dataset.fontUrl + ')');
//       document.fonts.add(font);
//       font.load().then(() => {
//         this.$el.removeAttribute('x-cloak');
//         // console.log('Initialized with fontFeatures:', this.fontFeatures); // Log the initial value
//         // console.log('Font name:', this.fontName);
//         // console.log('Font URL:', this.$el.dataset.fontUrl);
//         // this.initTypewriter();
//         this.setupEventListeners();
//       });
//     },




//   initTypewriter() {
//     const el = this.$el.querySelector('.typetester_editable');
//     console.log('el:', this.$el);
//     if (el && el.dataset.text) {
//           console.log('from inside the typewriter...');
//           const strings = el.dataset.text.split('.').map(str => str.trim()).filter(str => str.length > 0);       
//           console.log('Strings:', strings);   
//           this.typewriter = new Typewriter(el, {
//               strings,
//               autoStart: true,
//               // delay: "natural",
//               delay: 40,
//               deleteSpeed: 50,
//               pauseFor: 2000,
//               loop: true,
//               cursor: '',
//               // cursorClassName: 'big_typetester_cursor',
//           });
//       }
//   },
//   pauseTyping() {
//       this.interactingWithControls = true;
//       if (this.typewriter) {
//           this.typewriter.pause();
//       }
//   },
//   startTyping() {
//       if (this.typewriter) {
//           this.typewriter.start();
//       }
//   },
//   setupEventListeners() {
//     const el = this.$el.querySelector('.typetester_editable');
//     el.addEventListener('focus', () => this.pauseTyping());
//     el.addEventListener('blur', () => {
//       if (this.isTyping) {
//         this.startTyping();
//       }
//     });
//   },



//   toggleTypewriter() {
//     this.isTyping = !this.isTyping;
//     console.log('isTyping:', this.isTyping);
//     if (this.isTyping) {
//       if (!this.typewriter) {
//         console.log('Typewriter not initialized. Initializing now...');
//         console.log('Typewriter:', this.typewriter);
//         this.initTypewriter();
//       }
//       this.startTyping();
//     } else {
//       this.pauseTyping();
//     }
//   },
//     toggleCase() {
//       if (this.caseText === 'Aa') {
//         this.caseText = 'AA';
//         this.caseClass = 'upper_case';
//       } else if (this.caseText === 'AA') {
//         this.caseText = 'aa';
//         this.caseClass = 'lower_case';
//       } else if (this.caseText === 'aa') {
//         this.caseText = 'Aa';
//         this.caseClass = 'title_case';
//       }
//     },
//   };
// }


// import Typewriter from 'typewriter-effect/dist/core';

// export default function typeTester() {
//   return {
//     size: 0,
//     breakpoints: [
//       { min: 200, max: 1000, columns: 1 },
//       { min: 51, max: 199, columns: 2 },
//       { min: 25, max: 50, columns: 3 },
//       { min: 16, max: 24, columns: 4 },
//     ],
//     leading: 1,
//     ot: [],
//     caseText: 'Aa',
//     caseClass: '',
//     align: 'center',
//     features: false,
//     figs: false,
//     slide: false,
//     typewriter: null,
//     interactingWithControls: false,
//     isTyping: false,
//     text: '',
//     mainElement: null,
//     get fontFeatures() {
//       return this.ot.length > 0 ? this.ot.map(f => `"${f}"`).join(', ') : 'normal';
//     },
//     get editableStyle() {
//       return {
//         'font-family': this.fontName ? `'${this.fontName}'` : 'sans-serif',
//         'font-size': `${this.size}px`,
//         'text-align': this.align || 'center',
//         'font-feature-settings': this.fontFeatures,
//         'line-height': this.leading,
//         'columns': this.breakpoints.find(b => this.size >= b.min && this.size <= b.max).columns,
//       };
//     },
//     initAlpine() {
//       this.mainElement = this.$el;
//       this.size = parseInt(this.mainElement.dataset.size) || 60;
//       this.ot = this.mainElement.dataset.ot ? this.mainElement.dataset.ot.split(",") : [];
//       this.fontName = this.mainElement.dataset.fontName || 'sans-serif';
//       this.leading = parseFloat(this.mainElement.dataset.leading) || 1.5;
//       this.align = this.mainElement.dataset.alignment || 'center';

//       const font = new FontFace(this.mainElement.dataset.fontName, 'url(' + this.mainElement.dataset.fontUrl + ')');
//       document.fonts.add(font);
//       font.load().then(() => {
//         this.mainElement.removeAttribute('x-cloak');
//         this.setupEventListeners();
//       });
//     },
//     initTypewriter() {
//       const el = this.mainElement.querySelector('.typetester_editable');
//       if (el && el.dataset.text) {
//         // const strings = el.dataset.text.split('.').map(str => str.trim()).filter(str => str.length > 0);
//         const strings = el.dataset.text.split(/<\/?p>/).map(str => str.trim()).filter(str => str.length > 0);
//         this.typewriter = new Typewriter(el, {
//           strings,
//           autoStart: true,
//           delay: "natural",
//           deleteSpeed: 0,
//           pauseFor: 3000,
//           loop: true,
//           cursor: '',
//           cursorClassName: 'big_typetester_cursor',
//         });
//       }
//     },
//     resetTypewriter() {
//       if (this.typewriter) {
//         this.typewriter.stop();
//         this.initTypewriter();
//       }
//     },
//     pauseTyping() {
//       this.interactingWithControls = true;
//       if (this.typewriter) {
//         this.typewriter.pause();
//       }
//     },
//     // startTyping() {
//     //   if (this.typewriter) {
//     //     this.typewriter.start();
//     //   }
//     // },
//     startTyping() {
//       if (this.typewriter) {
//         if (this.mainElement.querySelector('.typetester_editable').textContent.trim() === '') {
//           this.resetTypewriter();
//         }
//         this.typewriter.start();
//       }
//     },
//     // setupEventListeners() {
//     //   const el = this.mainElement.querySelector('.typetester_editable');
//     //   el.addEventListener('focus', () => this.pauseTyping());
//     //   el.addEventListener('blur', () => {
//     //     if (this.isTyping) {
//     //       this.startTyping();
//     //     }
//     //   });
//     // },
//     setupEventListeners() {
//       const el = this.mainElement.querySelector('.typetester_editable');
//       el.addEventListener('focus', () => {
//         this.pauseTyping();
//       });
//       el.addEventListener('blur', () => {
//         if (this.isTyping) {
//           this.startTyping();
//         }
//       });
//       el.addEventListener('input', () => {
//         if (el.textContent.trim() === '') {
//           this.resetTypewriter();
//         }
//       });
//     },
//     toggleTypewriter() {
//       this.isTyping = !this.isTyping;
//       console.log('Typewriter:', this.isTyping);
//       if (this.isTyping) {
//         if (!this.typewriter) {
//           // console.log('Typewriter not initialized. Initializing now...');
//           this.initTypewriter();
//         }
//         this.startTyping();
//       } else {
//         this.pauseTyping();
//       }
//     },
//     toggleCase() {
//       if (this.caseText === 'Aa') {
//         this.caseText = 'AA';
//         this.caseClass = 'upper_case';
//       } else if (this.caseText === 'AA') {
//         this.caseText = 'aa';
//         this.caseClass = 'lower_case';
//       } else if (this.caseText === 'aa') {
//         this.caseText = 'Aa';
//         this.caseClass = 'title_case';
//       }
//     },
//   };
// }


import Typewriter from 'typewriter-effect/dist/core';

export default function typeTester() {
  return {
    size: 0,
    // breakpoints: [
    //   { min: 200, max: 1000, columns: 1 },
    //   { min: 51, max: 199, columns: 2 },
    //   { min: 25, max: 50, columns: 3 },
    //   { min: 16, max: 24, columns: 4 },
    // ],
    leading: 1,
    ot: [],
    caseText: 'Aa',
    caseClass: '',
    align: 'center',
    features: false,
    figs: false,
    slide: false,
    typewriter: null,
    interactingWithControls: false,
    isTyping: false,
    text: '',
    mainElement: null,
    initialHeights: {},

    get fontFeatures() {
      return this.ot.length > 0 ? this.ot.map(f => `"${f}"`).join(', ') : 'normal';
    },

    get editableStyle() {
      return {
        'font-family': this.fontName ? `'${this.fontName}'` : 'sans-serif',
        'font-size': `${this.size}px`,
        'text-align': this.align || 'center',
        'font-feature-settings': this.fontFeatures,
        'line-height': this.leading,
        // 'columns': this.breakpoints.find(b => this.size >= b.min && this.size <= b.max).columns,
      };
    },

    initAlpine() {
      this.mainElement = this.$el;
      this.size = parseInt(this.mainElement.dataset.size) || 60;
      this.ot = this.mainElement.dataset.ot ? this.mainElement.dataset.ot.split(",") : [];
      this.fontName = this.mainElement.dataset.fontName || 'sans-serif';
      this.leading = parseFloat(this.mainElement.dataset.leading) || 1.5;
      this.align = this.mainElement.dataset.alignment || 'center';

      const font = new FontFace(this.mainElement.dataset.fontName, 'url(' + this.mainElement.dataset.fontUrl + ')');
      document.fonts.add(font);
      font.load().then(() => {
        this.mainElement.removeAttribute('x-cloak');
        this.setupEventListeners();
        this.storeInitialHeights();
        this.updateHeights();
      });
    },

    storeInitialHeights() {
      const elements = this.mainElement.querySelectorAll('.typetester_editable');
      elements.forEach(el => {
        this.initialHeights[el.dataset.id] = el.offsetHeight;
      });
    },

    updateHeights() {
      const elements = this.mainElement.querySelectorAll('.typetester_editable');
      elements.forEach(el => {
        el.style.minHeight = `${this.initialHeights[el.dataset.id]}px`;
      });
    },

    initTypewriter() {
      const el = this.mainElement.querySelector('.typetester_editable');
      if (el && el.dataset.text) {
        const strings = el.dataset.text.split(/<\/?p>/).map(str => str.trim()).filter(str => str.length > 0);
        this.typewriter = new Typewriter(el, {
          strings,
          autoStart: true,
          delay: "natural",
          deleteSpeed: 0,
          pauseFor: 3000,
          loop: true,
          cursor: '',
          cursorClassName: 'big_typetester_cursor',
        });
      }
    },

    resetTypewriter() {
      if (this.typewriter) {
        this.typewriter.stop();
        this.initTypewriter();
      }
    },

    pauseTyping() {
      this.interactingWithControls = true;
      if (this.typewriter) {
        this.typewriter.pause();
      }
    },

    startTyping() {
      if (this.typewriter) {
        if (this.mainElement.querySelector('.typetester_editable').textContent.trim() === '') {
          this.resetTypewriter();
        }
        this.typewriter.start();
      }
    },

    setupEventListeners() {
      const el = this.mainElement.querySelector('.typetester_editable');
      el.addEventListener('focus', () => {
        this.pauseTyping();
      });
      el.addEventListener('blur', () => {
        if (this.isTyping) {
          this.startTyping();
        }
      });
      el.addEventListener('input', () => {
        if (el.textContent.trim() === '') {
          this.resetTypewriter();
        }
        this.updateHeights();
      });
    },

    toggleTypewriter() {
      this.isTyping = !this.isTyping;
      if (this.isTyping) {
        if (!this.typewriter) {
          this.initTypewriter();
        }
        this.startTyping();
      } else {
        this.pauseTyping();
      }
    },

    toggleCase() {
      if (this.caseText === 'Aa') {
        this.caseText = 'AA';
        this.caseClass = 'upper_case';
      } else if (this.caseText === 'AA') {
        this.caseText = 'aa';
        this.caseClass = 'lower_case';
      } else if (this.caseText === 'aa') {
        this.caseText = 'Aa';
        this.caseClass = 'title_case';
      }
    },
  };
}