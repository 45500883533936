import List from 'list.js';
import { handleOverflowingText } from '../../utilities/text_overflow.js';

const indexView = () => {
  // Check if the element with id "index" exists
  const indexElement = document.getElementById('index');
  
  if (!indexElement) {
    // If the element doesn't exist, exit the function
    return;
  }


  const options = {
    valueNames: [
      'section_index--year',
      'section_index--type',
      'section_index--title',
      'section_index--client',
    ],
  };

  
  const indexLinks = document.querySelectorAll('#section_index a');
  indexLinks.forEach((link) => {
    link.addEventListener('click', (e) => {
      // if (!isDragging) {
      //     e.preventDefault();
      //     e.stopPropagation();
          Alpine.store('viewsStore').setView('work');
          // swup.navigate(e.currentTarget.getAttribute("data-ref"));
      // }
    });
  });

  // Initialize List.js
  const sectionIndexList = new List('index', options);

  let activeSortButton = null;
  let activeFilterButton = null;
  let currentSortOrder = 'asc';
  
  // Initial sort
  sectionIndexList.sort('section_index--year', { order: 'desc' });

  // Function to handle sorting
  const handleSort = (button) => {
    const sortData = button.getAttribute('data-sort').split(':');
    const sortBy = sortData[0];

    // Toggle sorting order
    currentSortOrder = currentSortOrder === 'asc' ? 'desc' : 'asc';

    // Update data-sort attribute with the new sorting order
    button.setAttribute('data-sort', `${sortBy}:${currentSortOrder}`);

    sectionIndexList.sort(sortBy, { order: currentSortOrder });
    
    // Add "active" class to the clicked button and remove it from others
    if (activeSortButton) {
      activeSortButton.classList.remove('active');
    }
    button.classList.add('active');
    activeSortButton = button;
  };

  // Function to handle filtering
  const handleFilter = (button) => {
    const type = button.getAttribute('data-filter');
    
    if (type === 'all') {
      sectionIndexList.filter();
    } else {
      sectionIndexList.filter((item) => {
        const typeElement = item.elm.querySelector('.section_index--type');
        return typeElement && typeElement.textContent.trim() === type;
      });
    }

    // Add "active" class to the clicked button and remove it from others
    if (activeFilterButton) {
      activeFilterButton.classList.remove('active');
    }
    button.classList.add('active');
    activeFilterButton = button;
  };

  // Attach event listeners to sorting buttons
  const sortingButtons = document.querySelectorAll('[data-sort]');

  sortingButtons.forEach((button) => {
    button.addEventListener('click', () => {
      handleSort(button);
    });
  });

  // Attach event listeners to filter buttons
  const filterButtons = document.querySelectorAll('[data-filter]');
  
  filterButtons.forEach((button) => {
    button.addEventListener('click', () => {
      handleFilter(button);
    });
  });

  // Initially filter to show all items
  const allButton = document.querySelector('[data-filter="all"]');
  if (allButton) {
    handleFilter(allButton);
  }
  

  handleOverflowingText('.section_index--type');
  handleOverflowingText('.section_index--title');
  handleOverflowingText('.section_index--client');


};

// Call the function to initialize everything
// document.addEventListener('DOMContentLoaded', indexView);

export default indexView;