import Muuri from 'muuri';
import imagesLoaded from 'imagesloaded';
import Swup from 'swup';



function thumbnailView() {

    const projectLinks = document.querySelectorAll('.project__link');
    const projectLinksArray = Array.from(projectLinks);

    const thumbnail_view = document.querySelector('.thumbnail_view');
    let packed = document.querySelector('.packed');

    function shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    let dragEnabled = false;

    if (window.matchMedia("(min-width: 768px)").matches) {
        dragEnabled = true;
    }

    if (document.querySelector('.packed') == null) {
        return;
    }
    const grid = new Muuri('.packed', {
        dragEnabled: dragEnabled,
        items: '.project__link',
        fillGaps: true,
        horizontal: true,
        showDuration: 0,
        hideDuration: 0,
        layoutDuration: 0,
        layoutOnResize: false, // På grunn av Alpine, se init i app.js
        layoutOnInit: false, // På grunn av Alpine, se init i app.js
        dragSortInterval: 0,
        dragSortPredicate: {
            action: 'move',
            threshold: 10,
            isValidDrag: function (item, e) {
                return e.target.classList.contains('project__link');
            },
        },
    });

    // Get all the filter buttons
    const filterButtons = document.querySelectorAll('.filter-btn[data-type]');
    const randomizeBtn = document.querySelector('.randomize-btn');
    const sortBtn = document.querySelector('.sort-btn');

    // Attach click event listeners to each filter button if not already attached
    filterButtons.forEach(button => {
        if (!button.dataset.listener) {
            button.dataset.listener = true;
            button.addEventListener('click', () => {
                const projectType = button.dataset.type;

                // Add active class to the clicked button
                filterButtons.forEach(btn => btn.classList.remove('active'));
                button.classList.add('active');

                // Filter the grid based on the selected project type
                if (projectType === 'all') {
                    grid.filter('[data-type]'); // Show all items
                } else {
                    grid.filter((item) => {
                        const dataTypes = item.getElement().getAttribute('data-type').split(',').map(type => type.trim()); // Split comma-separated values into an array and trim each value
                        return dataTypes.includes(projectType); // Check if the selected project type is included in the dataTypes array
                    });
                }
            });
        }
    });

    randomizeBtn.addEventListener('click', () => {
        grid.filter('.project__link'); // Show all items
        grid.sort(grid.getItems(shuffle(projectLinksArray)));
    });

    // Store the original order of the items
    const originalOrder = grid.getItems();

    // Define the sorting states
    const sortStates = [
        { attribute: 'size', text: 'Size S → XL', comparator: ascendingComparator },
        { attribute: 'size-desc', text: 'Size XL → S', comparator: descendingComparator },
        { attribute: 'original-order', text: 'Sort by Size', comparator: null }
    ];

    // Set the initial sort state
    let currentSortState = 0;

    // Add click event listener to the button
    sortBtn.addEventListener('click', function () {
        // Toggle sorting based on the current sort state
        const currentSort = sortStates[currentSortState];

        if (currentSort.comparator) {
            grid.sort(currentSort.comparator);
            sortBtn.setAttribute('data-sort', currentSort.attribute);
            sortBtn.textContent = currentSort.text;
        } else {
            grid.sort(originalOrder);
            sortBtn.setAttribute('data-sort', currentSort.attribute);
            sortBtn.textContent = currentSort.text;
        }

        // Update the current sort state
        currentSortState = (currentSortState + 1) % sortStates.length;
    });

    // Comparator for ascending sort
    function ascendingComparator(itemA, itemB) {
        const aSize = parseFloat(itemA.getElement().getAttribute('data-size'));
        const bSize = parseFloat(itemB.getElement().getAttribute('data-size'));
        return aSize - bSize;
    }

    // Comparator for descending sort
    function descendingComparator(itemA, itemB) {
        const aSize = parseFloat(itemA.getElement().getAttribute('data-size'));
        const bSize = parseFloat(itemB.getElement().getAttribute('data-size'));
        return bSize - aSize;
    }

    const slider = document.getElementById('zoom-slider');

    slider.addEventListener('input', (event) => {
        const zoomValue = event.target.value;
        document.documentElement.style.setProperty('--zoompack', zoomValue);
        grid.refreshItems().layout();
    });

    projectLinks.forEach((link) => {
        let isDragging = false;
        let startX = 0;
        let startY = 0;

        link.addEventListener('click', (e) => {
            if (!isDragging) {
                e.preventDefault();
                e.stopPropagation();
                Alpine.store('viewsStore').setView('work');
                swup.navigate(e.currentTarget.getAttribute("data-ref"));

                // go to the top of the page
            }
        });

        function startDragging(x, y) {
            startX = x;
            startY = y;
            isDragging = false;
        }

        function updateDragging(x, y) {
            const deltaX = Math.abs(x - startX);
            const deltaY = Math.abs(y - startY);

            if (deltaX > 10 || deltaY > 10) {
                isDragging = true;
            }
        }

        link.addEventListener('mousedown', (e) => {
            startDragging(e.clientX, e.clientY);
        });

        link.addEventListener('mousemove', (e) => {
            updateDragging(e.clientX, e.clientY);
        });
    });

    window.thumbnailView = thumbnailView;
    window.grid = grid;
    window.imagesLoaded = imagesLoaded;
}

export default thumbnailView;