export default function videoRollover(btnSelector, videoSelector) {
  const btn = document.querySelector(btnSelector);
  const video = document.querySelector(videoSelector);

  if (!btn || !video) {
    // console.error('Button or video element not found');
    return;
  }

  // Initializing values
  var isRolloverPlaying = true;
  // On video playing toggle values
  video.onplaying = function() {
    isRolloverPlaying = true;
  };
  // On video pause toggle values
  video.onpause = function() {
    isRolloverPlaying = false;
  };
  // Play video function
  async function playVid() {      
      if (video.paused && !isRolloverPlaying) {
          return video.play();
      }
  } 
  // Pause video function
  function pauseVid() {     
      if (!video.paused && isRolloverPlaying) {
          video.pause();
      }
  }

  btn.addEventListener("mouseenter", () => {
    video.classList.remove('hidden');
    video.play();
    // playVid();
    video.addEventListener('ended', onVideoEnded);
  });

  btn.addEventListener("mouseleave", () => {
    video.classList.add('hidden');
    video.pause();
    // pauseVid();
    video.currentTime = 0;
  });

  function onVideoEnded() {
    video.classList.add('hidden');
  }
}
