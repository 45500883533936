const observeVideos = async () => {
  const videos = document.querySelectorAll("video");
  console.log('videos are', videos);

  if (videos.length === 0) {
    return;
  }

  let userInitiatedPlayback = false;

  const playVid = async (video) => {
    if (video.paused) {
      await video.play();
      console.log('play');
    }
  };

  const pauseVid = (video) => {
    if (!video.paused) {
      video.pause();
      console.log('pause');
    }
  };

  videos.forEach((video) => {
    // Initializing values
    let isPlaying = false;

    // On video playing toggle values
    video.onplaying = function () {
      isPlaying = true;
    };

    // On video pause toggle values
    video.onpause = function () {
      isPlaying = false;
    };

    video.muted = true;
    video.addEventListener("play", () => {
      userInitiatedPlayback = true;
      video.muted = true;
    });

    const playPromise = video.play();

    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          const observer = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                if (userInitiatedPlayback) {
                  if (entry.intersectionRatio !== 1 && !video.paused) {
                    pauseVid(video);
                    // console.log("pause");
                  } else if (video.paused) {
                    playVid(video);
                    // console.log("play");
                  }
                }
              });
            },
            { threshold: 0.2 }
          );
          observer.observe(video);
        })
        .catch((error) => {
          // Autoplay was blocked, show video controls
          video.controls = true;
        });
    }
  });
};
 
export { observeVideos as default };
