export function resizeSVGPath(el, newHeight) {
    const svgElement = document.querySelector(el);
    const pathElement = svgElement.querySelector("path");
    if (!pathElement) {
        // console.error("Path element with ID 'hey' not found.");
        return;
    } 

    const originalHeight = svgElement.getAttribute("viewBox").split(" ")[3];

    const originalPathData = pathElement.getAttribute("d");
    const heightScaleFactor = newHeight / originalHeight;

    // Split the path data into individual commands
    const pathCommands = originalPathData.split(/(?=[A-Za-z])/);

    // Process each command and scale the Y coordinates
    const scaledPathCommands = pathCommands.map(command => {
        const matches = command.match(/[A-Za-z]|[-+]?\d*\.?\d+/g);
        if (!matches) return command;

        const scaledValues = matches.map((value, index) => {
            if (index === 0) return value; // Keep the command letter as is
            if (index % 2 === 1) return value; // Keep X coordinates as is
            const scaledYValue = parseFloat(value) * heightScaleFactor;
            return scaledYValue.toFixed(2); // Round to 2 decimal places
        });

        return scaledValues.join(" ");
    });

    // Combine the scaled path commands back into a single string
    const scaledPathData = scaledPathCommands.join("");

    // Set the new scaled path data
    pathElement.setAttribute("d", scaledPathData);
}

export function resizeSVG(el, newHeight) {
    const svgElement = document.querySelector(el);
    const originalWidth = svgElement.getAttribute("viewBox").split(" ")[2];
    const originalHeight = svgElement.getAttribute("viewBox").split(" ")[3];
    
    if (!svgElement) {
        // console.error("SVG element with the specified ID not found.");
        return;
    }

    const width = svgElement.getAttribute("width");
    const aspectRatio = width / originalHeight;

    const newWidth = newHeight * aspectRatio;

    // svgElement.setAttribute("height", newHeight);
    svgElement.setAttribute("viewBox", `0 0 ${originalWidth} ${newHeight}`);
}