function handleOverflowingText(selector) {
  const elements = document.querySelectorAll(selector);
  
  if (elements.length === 0) {
    // console.warn(`No elements found matching selector: ${selector}`);
    return;
  }

  elements.forEach((parent) => {
    const child = parent.querySelector('.overflowing_text_inner');
    
    if (!child) {
      // console.warn(`No child element with class 'overflowing_text_inner' found in ${selector}`);
      return;
    }

    // Use getBoundingClientRect for more accurate width comparison
    if (child.getBoundingClientRect().width > parent.getBoundingClientRect().width) {
      // Child element overflows its container
      const overflowingText = document.createElement('span');
      overflowingText.classList.add('overflowing_text');

      const cloneCount = 3; // Number of clones to create

      for (let i = 0; i < cloneCount; i++) {
        const clone = child.cloneNode(true);
        overflowingText.appendChild(clone);
      }

      parent.innerHTML = ''; // Clear parent content
      parent.appendChild(overflowingText);
    } else {
      // No overflow, but we might want to log this for debugging
      // console.log(`No overflow detected for element in ${selector}`);
    }
  });
}

export { handleOverflowingText };





