import playGlockenspiel from './glock.js';
import funStuff from './fun_stuff/funStuff.js';
import globalVariables from './variables/variables.js';
// import navHeight from './headers/sticky_headers.js';
import imagesLoaded from 'imagesloaded';

import { handleOverflowingText } from 'utilities/text_overflow.js';
import { resizeSVGPath } from 'utilities/responsivesvg.js';
import { resizeSVG } from 'utilities/responsivesvg.js';

import indexView from './views/index/index.js';
import thumbnailView from './views/thumbnails/thumbnails.js';
import { initPong } from './fun_stuff/pong_module.js';
import observeVideos from './videos/observe_videos.js';
import videoRollover from './fun_stuff/video_rollovers.js';
import posters from './blocks/posters.js';
import flippers from './blocks/flippers.js';
import typeTester from './blocks/typetester.js';
import { googlyEyes } from './fun_stuff/googlyEyes.js';

import SwupHeadPlugin from '@swup/head-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';

import Swup from 'swup';

const swup = new Swup({
	containers: ["#swup", "#swup_header"],
	// cache: false,
	plugins: [
		new SwupHeadPlugin(),
		new SwupBodyClassPlugin(),
		new SwupPreloadPlugin(),
	],
  });

window.swup = swup;

funStuff();

// Alpine stuff
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect'
Alpine.plugin(intersect);






// window.indexView = indexView;
window.Alpine = Alpine;

let packed = document.querySelector('.packed');
let layoutInitiated = false; // Mark that the initial layout has not been done

Alpine.data('typeTester', typeTester);

const viewsStore = {
    currentView: window.location.hash === '#about' ? 'about' : (window.location.hash === '#contact' ? 'contact' : 'work'),
    lastToggleTime: 0,
	setView(view) {
        this.currentView = view;
    },
    isActive(view) {
        return this.currentView === view;
    },
    toggleThumbnails() {
        const now = Date.now();
        if (now - this.lastToggleTime < 300) {  // 300ms debounce
            return;
        }
        this.lastToggleTime = now;

        if (this.currentView === 'thumbnails') {
            this.setView('work');
        } else {
            this.setView('thumbnails');
        }
    },
	toggleIndex() {
        const now = Date.now();
        if (now - this.lastToggleTime < 300) {  // 300ms debounce
            return;
        }
        this.lastToggleTime = now;

        if (this.currentView === 'index') {
            this.setView('work');
        } else {
            this.setView('index');
        }
    }
};
Alpine.store('viewsStore', viewsStore);










Alpine.data('programmatically', () => ({
	view: 'work',
	handleToggle() {
        this.$store.viewsStore.toggleThumbnails();
    },
	handleIndexToggle() {
        this.$store.viewsStore.toggleIndex();
    },
	init() {

		this.$watch('$store.viewsStore.currentView', value => {
			// console.log('view changed to ' + value);
			if (value === 'index') {
				document.body.classList.add('freeze'); 
				document.body.classList.remove('thumbnails-open'); 
				document.body.classList.add('index-open'); 
				if(document.querySelector('#matter_header')) {
					pauseMatter();
				}
				this.loadIndexContent();
			}
			else if (value === 'thumbnails') { 
				document.body.classList.add('freeze'); 
				document.body.classList.remove('index-open'); 
				document.body.classList.add('thumbnails-open'); 
				if(document.querySelector('#matter_header')) {
					pauseMatter();
				}
				this.loadThumbnailContent();

			} else if (value === 'work') { 
				document.body.classList.remove('freeze'); 
				document.body.classList.remove('thumbnails-open'); 
				document.body.classList.remove('index-open');
				if(document.querySelector('#matter_header')) {
					resumeMatter();
				}
			} else if (value === 'about') { 
				// document.body.classList.add('freeze'); 
				// document.body.classList.remove('dark'); 
			} else if (value === 'contact') {
				// document.body.classList.add('freeze'); 
				// document.body.classList.add('dark'); 
				// console.log('CONTACT view');
			} else { 
				document.body.classList.remove('freeze'); 
				// document.body.classList.remove('dark'); 
				// console.log('Default view');
			}
			
		});
		
	},
	loadIndexContent() {
        const indexDiv = document.getElementById('index');
        if (indexDiv && !indexDiv.hasChildNodes()) {
            fetch('/index')
                .then(response => response.text())
                .then(html => {
                    indexDiv.innerHTML = html;
					// console.log('Index content loaded');
					indexView();
                })
                .catch(error => {
                    // console.error('Failed to fetch index content:', error);
                });
        }
    },
	loadThumbnailContent() {
        const thumbnailDiv = document.getElementById('thumbnails');
        if (thumbnailDiv && !thumbnailDiv.hasChildNodes()) {
            fetch('/thumbnails')
                .then(response => response.text())
                .then(html => {
                    thumbnailDiv.innerHTML = html;
					// console.log('Thumbnail content loaded');
					thumbnailView();
								grid.layout(function () {
										grid.refreshItems().layout();
										// document.querySelector('.thumbnail_view').scrollTop = 0;
										// console.log(document.querySelector('.thumbnail_view').scrollTop);
										layoutInitiated = true; // Mark that the initial layout has been done
										// initializeLazyLoad();					  
								});
		
								if (packed.offsetHeight === 0) {
									setTimeout(function(){
										grid.refreshItems().layout();
										layoutInitiated = true;
									}, 10);
								}
                })
                .catch(error => {
                    // console.log('Failed to fetch thumbnail content:', error);
                });
        }
    }
}));








Alpine.data('newsletterForm', () => ({
	success: false,
	trialfonts: false,
	error: false,
	message: '',

	async submitForm(event) {
	  const form = event.target;
	  const formData = new FormData(form);

	  try {
		const response = await fetch(form.action, {
		  method: form.method,
		  body: formData,
		  headers: {
			'Accept': 'application/json'
		  }
		});

		console.log(response); // Log the response

		if (response.ok) {
			this.success = true;
			this.error = false;
			this.message = 'Yesss! Look out for that email, and let’s keep in touch.';
		} else {
			const errorData = await response.json();
			console.log(errorData); // Log the error data
			this.success = false;
			this.error = true;
			this.message = errorData.message || 'Woops, there was an error.';
		}
		} catch (error) {
		console.log(error); // Log the error
		this.success = false;
		this.error = true;
		// this.message = 'Big oof, a network error occurred.';
		this.message = 'Yesss! Look out for that email, and let’s keep in touch.';
	  }
	}
  }));




// console.log( Alpine.store('viewsStore') );
// import cursor from 'alpinejs-cursor' // not working
// Alpine.plugin(cursor) // something makes this load twice and it doesn't add the cursor class to the body



window.onload = function() {
	if (window.matchMedia('(hover: hover)').matches) {

		if (document.querySelector('.services')) {
			initPong();
		}
	}
}


window.addEventListener('DOMContentLoaded', (event) => {
	
	if (document.querySelector('#matter_header')) {
		playGlockenspiel();
	}

	globalVariables();
	
	// Headers and navigation //
	// navHeight(); 

	// Videos //
	// observeVideos();
	handleOverflowingText('.section_index--type');
  	handleOverflowingText('.section_index--title');
  	handleOverflowingText('.section_index--client');
	handleOverflowingText('.header-client');
	  

	videoRollover("#about_btn", "#about__video");
	videoRollover("#contact_btn", "#contact__video");
	videoRollover("logo_btn", "#logo__video");

	if (document.querySelector(".posters") ) {
		posters();
	}

	if (window.matchMedia('(hover: hover)').matches) {
		document.body.classList.add('can-hover');
	} else {
		document.body.classList.add('no-hover');
	}

	if (window.matchMedia('(hover: hover)').matches) {

		if (document.querySelector(".flipper") ) {
			flippers();
		}

	}

	if (window.matchMedia("(max-width: 768px)").matches) {
		if (document.querySelector("#hey") ) {
			resizeSVGPath("#hey", 2300); 
        	resizeSVG("#hey", 2300);
		}
        
    }

	

	if (document.querySelector('.contact')) {
		googlyEyes("contact");
	}


});












  


	// Resize stuff but not too often
	function debounce(func, wait, immediate) {
		var timeout;
		return function() {
			var context = this,
				args = arguments;
			var later = function() {
				timeout = null;
				if (!immediate)
					func.apply(context, args);

			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow)
				func.apply(context, args);

		};
	};

	let dwidth = window.innerWidth;

	// let oldScrollPos = 0;
	// let newScrollPos = 0;

	window.addEventListener("resize", debounce(function() {
		// let wwidth = window.innerWidth;
		// if (dwidth !== wwidth) {
		// 	dwidth = window.innerWidth;
			// Only do stuff if the width has changed
			// navHeight();
			// get which view is active in alpine when reisizing
			let resizedView = Alpine.store('viewsStore').currentView;
			// console.log(resizedView);
			if (resizedView !== 'thumbnails') {
				// newScrollPos = document.querySelector('.thumbnail_view').scrollTop = 0;
				layoutInitiated = false;
				// document.querySelector('.thumbnail_view').scrollTop = 0;
				// console.log("resize is not thumbnails, layoutInitiated: " + layoutInitiated);
				// grid.refreshItems().layout();
			}
			else {
				grid.refreshItems().layout();
			// 	imagesLoaded(packed, () => {
			// 		grid.refreshItems().layout();
			// 		layoutInitiated = true;
			// 		// console.log("resizzzze is thumbnails, layoutInitiated: " + layoutInitiated);
			// 		if (packed.offsetHeight === 0) {
			// 			// document.querySelector('.thumbnail_view').scrollTop = 0;
			// 			// UÆÆÆ
			// 			// console.log('packed height is 0, not loaded yet');
			// 			// grid.refreshItems().layout();
			// 			// layoutInitiated = true;
			// 			setTimeout(function(){
			// 				grid.refreshItems().layout();
			// 				layoutInitiated = true;
			// 				// console.log('layout done?');
			// 			}, 10);
			// 		}
			// 	});


			// grid.layout(function () {
			// 	grid.refreshItems().layout();
			// 	// document.querySelector('.thumbnail_view').scrollTop = 0;
			// 	// console.log(document.querySelector('.thumbnail_view').scrollTop);
			// 	layoutInitiated = true; // Mark that the initial layout has been done
			// 	initializeLazyLoad();					  
			// });

			// if (packed.offsetHeight === 0) {
			// 	setTimeout(function(){
			// 		grid.refreshItems().layout();
			// 		layoutInitiated = true;
			// 	}, 10);
			// }


			}
	}, 10))

	Alpine.start();





	// new HoverCursor({
	// 	containerQuery: '.next-button',
	// 	snapPosition: 'R',
	// 	customClass: 'test',
	// 	icon: '/assets/icons/arrow-right.svg',
	// })


	
	swup.hooks.on('page:view', () => {

		Alpine.store('viewsStore').setView('work');
	
	
		
		// if (document.querySelector('#carousel')) {
		//   const carousel = new Carousel('#carousel');
		// }
	
		if (document.querySelector('#matter_header')) {
			playGlockenspiel();
		}
		
		if (document.querySelector(".posters") ) {
			posters();
		}
		
		if (window.matchMedia('(hover: hover)').matches) {
			document.body.classList.add('can-hover');
		} else {
			document.body.classList.add('no-hover');
		}

		if (window.matchMedia('(hover: hover)').matches) {
	
			if (document.querySelector(".flipper") ) {
				flippers();
			}
			if (document.querySelector('.services')) {
				initPong();
			}
	
		}
	




		if (document.querySelector('#typefaces')) {
			funStuff();
		}
		if (document.querySelector('#matter_header')) {
			funStuff();
		}




		

		// if (document.querySelector('.typetester')) {
		// 	typer();
		// }	
		
		if (document.querySelector('.contact')) {
			googlyEyes("contact");
		}

		if (window.matchMedia("(max-width: 768px)").matches) {
			if (document.querySelector("#hey") ) {
				resizeSVGPath("#hey", 1900); 
				resizeSVG("#hey", 1900);
			}
		}

		// navHeight(); 
		observeVideos();
		handleOverflowingText('.section_index--type');
		  handleOverflowingText('.section_index--title');
		  handleOverflowingText('.section_index--client');
		  handleOverflowingText('.header-client');
	
	
	
	  });

	  swup.hooks.on('content:scroll', () => {
		// select #swup and scroll to top
		// document.querySelector('#swup').scrollTo(0, 0);
		window.scrollTo(0, 0);
		console.log('scrolling to top');
	});

	

