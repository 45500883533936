function wave(selector) {
    const elements = document.querySelectorAll(selector);
  
    elements.forEach(element => {
      const text = element.innerText;
      const wrappedText = [...text].map(char => `<span>${char}</span>`).join('');
  
      element.innerHTML = wrappedText;
    });
  }

export { wave as default };
