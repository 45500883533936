export function googlyEyes(parentClass) {

    const parentElements = Array.from(document.getElementsByClassName(parentClass));
  
    function handleMouseMove(event) {
  
      parentElements.forEach(parent => {
  
        const eyes = Array.from(parent.querySelectorAll('.eye'));
  
        eyes.forEach(eye => {
  
          const iris = eye.querySelector('.iris');
  
          const irisWidth = iris.offsetWidth;
          const irisHeight = iris.offsetHeight;
  
          // const xPadding = irisWidth * 0.02;
          // const yPadding = irisHeight * 0.02;

          const xPadding = irisWidth / 5;
          const yPadding = irisHeight / 5;
          
          const { clientX, clientY } = event;
  
          const { left, right, top, bottom } = eye.getBoundingClientRect();
          
          const x = Math.max(Math.min(clientX, right - xPadding), left + xPadding) - left;
          const y = Math.max(Math.min(clientY, bottom - yPadding), top + yPadding) - top;
          iris.style.transform = `translate(-50%, -50%) translate(${x}px, ${y/eye.offsetHeight*100}%)`;

          // iris.style.transform = `translate(-50%, -50%) translate(${x/eye.offsetWidth*100}%, 50%)`;

        });
  
      });
  
    }
  
    window.addEventListener('mousemove', handleMouseMove);

  }
