import Typewriter from 'typewriter-effect/dist/core';

export default function initializeTypingAnimation() {
    // Get all elements with the editable class
    const typedElements = document.querySelectorAll('.header_bio');

      // Function to check if the screen is small (e.g., max-width: 768px)
    function isSmallScreen() {
      return window.matchMedia('(max-width: 768px)').matches;
    }
  
    // Create an empty array to hold all the Typewriter instances
    const typedInstances = [];
  
    // Loop through each editable element
    typedElements.forEach((el) => {
      // Get the text from the element's data-text attribute
      const text = el.dataset.text;
  
      // If the element and text exist
      if (typeof el !== "undefined" && text) {
        // Split the text by the "|" delimiter to create an array of strings
        const strings = text.split('|');

        // Set different speeds based on the screen size
        const delay = isSmallScreen() ? 80 : 30;  // Slower speed for mobile
        const deleteSpeed = isSmallScreen() ? 50 : 7;  // Slower delete speed for mobile
  
        // Create a new Typewriter instance for the element
        const typed = new Typewriter(el, {
          strings,
          autoStart: true,
          shuffle: true,
          delay,
          deleteSpeed,
          pauseFor: 3000,
          loop: true,
          cursor: '&nbsp;(<span class="more_dot">.</span><span class="more_dot">.</span><span class="more_dot">.</span>)',
          cursorClassName: 'more_fun',
          onCreateTextNode: (character) => {
            const node = document.createTextNode(character);            
            if (isSmallScreen()) {
              setTimeout(() => {
                el.scrollLeft = el.scrollWidth;
              }, 0);
            }
            return node;
          }
        });
  
        // Pause the animation when the mouse enters the element
        el.addEventListener('mouseenter', () => {
          typed.pause();
        });
  
        // Start the animation when the mouse leaves the element
        el.addEventListener('mouseleave', () => {
          typed.start();
        });

        // Add the instance to the array of instances
        typedInstances.push(typed);
      }
    });

    return typedInstances;
}
