import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const flippers = (elementId) => {
  if (typeof gsap !== 'undefined') {
    gsap.registerPlugin(ScrollTrigger);

    // This flips through the pages of the things like books or other sequences of images (A Kirby Block)
    const show_next_flipper_page = (newSection, currentSection) => {
      if (newSection !== currentSection) {
        gsap.to(currentSection, { autoAlpha: 0, duration: 0 });
        gsap.to(newSection, { autoAlpha: 1, duration: 0 });
        currentSection = newSection;
      }
    };

    const wrapper = document.querySelector(".flipper");
    const pages = document.querySelectorAll('.flipper__page');
    wrapper.currentSection = pages[0];
    let currentIndex = 0;

    gsap.set(wrapper.currentSection, { autoAlpha: 1 });

    // Create a progress bar container for the flipper section
    const progressBarContainer = document.createElement('div');
    progressBarContainer.classList.add('progress-bar-container');
    wrapper.appendChild(progressBarContainer);

    // Create progress bar sections for each flipper page
    pages.forEach((page, i) => {
      const progressBarSection = document.createElement('div');
      progressBarSection.classList.add('progress-bar-section');
      progressBarContainer.appendChild(progressBarSection);

      progressBarSection.addEventListener('click', () => {
        navigateToPage(i);
      });
    });

        // Create next and previous buttons
        const prevButton = document.createElement('button');
        prevButton.classList.add('flipper-nav-button', 'prev', 'prev-button');
        wrapper.appendChild(prevButton);

        const nextButton = document.createElement('button');
        nextButton.classList.add('flipper-nav-button', 'next', 'next-button');
        wrapper.appendChild(nextButton);

    // Function to navigate to a specific page
    const navigateToPage = (index) => {
      if (index >= 0 && index < pages.length) {
        const targetOffset = wrapper.offsetTop + index * window.innerHeight;
        window.scrollTo({
          top: targetOffset,
          behavior: 'smooth',
        });
        currentIndex = index;
        updateButtonStates();
      }
    };

    // Function to update button states
    const updateButtonStates = () => {
      prevButton.disabled = currentIndex === 0;
      nextButton.disabled = currentIndex === pages.length - 1;
    };

    // Event listeners for next and previous buttons
    nextButton.addEventListener('click', () => navigateToPage(currentIndex + 1));
    prevButton.addEventListener('click', () => navigateToPage(currentIndex - 1));

    // Remove existing ScrollTriggers for the wrapper
    ScrollTrigger.getAll().forEach(trigger => {
        trigger.kill();
    });

    ScrollTrigger.create({
      trigger: wrapper.querySelector('.flipper__page-wrapper'),
      start: () => 'top top',
      end: () => `+=${(pages.length - 1) * innerHeight}`,
      pin: true,
    });

    pages.forEach((page, i) => {
      ScrollTrigger.create({
        trigger: wrapper,
        start: () => `top top-=${(i - 0.5) * innerHeight}`,
        end: () => `+=${innerHeight}`,
        onToggle: (self) => {
          if (self.isActive) {
            show_next_flipper_page(page, wrapper.currentSection);
            wrapper.currentSection = page;
            currentIndex = i;
            updateButtonStates();

            // Update the active progress bar section
            progressBarContainer.childNodes.forEach((section, index) => {
              section.classList.toggle('active', index === i);
            });
          }
        },
      });
    });

    // Function to call ScrollTrigger.refresh() when .ingress element expands or collapses
    const refreshOnIngressChange = () => {
      ScrollTrigger.refresh();
      console.log('refreshOnIngressChange');
    };

    const ingressElement = document.querySelector('.ingress');
    if (ingressElement) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            refreshOnIngressChange();
          }
        });
      });

      observer.observe(ingressElement, {
        attributes: true,
        attributeFilter: ['class'],
      });
    }

    // Initialize button states
    updateButtonStates();
  }
};

export default flippers;