import Flickity from 'flickity';
import './../../../../node_modules/flickity-hash/hash.js';

const posters = () => {
  // Sliding poster carousels with Flickity
  const carousels = document.querySelectorAll('.posters-inner');
  const args = {
    accessibility: true,
    resize: true,
    wrapAround: true,
    prevNextButtons: false,
    pageDots: false,
    percentPosition: true,
    imagesLoaded: true,
    setGallerySize: true,
    hash: true, 
    // lazyLoad: 20, // can't do this with wraparound option and lazyload apparently
    cellAlign: 'center',
    // selectedAttraction: 0.2,
    // friction: 0.8,
    // freeScroll: true,
    // freeScrollFriction: 0.03
  };

  // carousels.forEach((carousel) => {
  //   const mainTicker = new Flickity(carousel, args);
  // });

  carousels.forEach((carousel) => {
    let requestId;
    let speed = 0.6; // Initial speed of the carousel

    if (carousel.childNodes.length > 2) {
      const mainTicker = new Flickity(carousel, args);

      // Set initial position to be 0
      mainTicker.x = 0;

      function play() {
        mainTicker.x = mainTicker.x - speed; // Update the position of the carousel using the current speed
        mainTicker.settle(mainTicker.x);
        requestId = window.requestAnimationFrame(play);
      }

      // Main function to reduce the speed of the carousel.
      function slowDown() {
        speed = 0.1; // Reduce the speed of the carousel
      }

      // Resume normal speed on mouse out / focusout
      function resumeNormalSpeed() {
        speed = 0.6; // Return to the normal speed of the carousel
      }

      // Main function to cancel the animation.
      function pause() {
        if (requestId) {
          window.cancelAnimationFrame(requestId);
          requestId = undefined;
        }
      }

      // // Slow down on mouse over / focusin
      // carousel.addEventListener('mouseover', () => {
      //   slowDown();
      //   // pause();
      // }, false);
      // carousel.addEventListener('focusin', () => {
      //   slowDown();
      //   // pause();
      // }, false);

      // // Resume normal speed on mouse out / focusout
      // carousel.addEventListener('mouseout', () => {
      //   resumeNormalSpeed();
      //   // play();
      // }, false);
      // carousel.addEventListener('focusout', () => {
      //   resumeNormalSpeed();
      //   // play();
      // }, false);

      // // Intersection Observer to play only when element is in viewport
      // const observer = new IntersectionObserver((entries) => {
      //   entries.forEach((entry) => {
      //     if (entry.isIntersecting) {
      //       play();
      //       // console.log('current shit is', Alpine.store('viewsStore').currentView );
      //       console.log('move the posters');
      //     } else {
      //       pause();
      //       // console.log('current shit is', Alpine.store('viewsStore').currentView );
      //       console.log('stop the posters');
      //     }
      //   });
      // });

      // observer.observe(carousel);




      var isDragging = false;
  
      mainTicker.on('dragStart', function() {
        isDragging = true;
      });
  
      mainTicker.on('dragEnd', function() {
        setTimeout(function() {
          isDragging = false;
        }, 0);
      });
  
      carousel.addEventListener('click', function(event) {
        if (isDragging) {
          return;
        }
  
        var clickX = event.pageX - carousel.offsetLeft;
        var carouselWidth = carousel.offsetWidth;
  
        if (clickX < carouselWidth / 2) {
          mainTicker.previous();
        } else {
          mainTicker.next();
        }
      });
  
      carousel.addEventListener('mousemove', function(event) {
        var clickX = event.pageX - carousel.offsetLeft;
        var carouselWidth = carousel.offsetWidth;
  
        if (clickX < carouselWidth / 2) {
          carousel.classList.add('custom-cursor');
        } else {
          carousel.classList.remove('custom-cursor');
        }
      });


    }
  });



};

export default posters;
